@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

html, body {
  height: 100%;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background: #111;
}

