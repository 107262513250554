.mapContainer {
  position: relative;
  z-index: 0;
}

.timelet {
  padding: 7px;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  font-size: 10px;
  line-height: 140%;
  -webkit-border-radius: 5px;
  border-radius: 0px 5px 0px 0px;

  a {
    color: #1d6799;

    &:hover {
      color: #458ebf;
    }
  }
}

.plotContainer {
  position: relative;
  z-index: 0,
}
