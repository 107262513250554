
.header {
  background: no-repeat middle;
  background-size: cover;
  text-align: center;
  height: 150px;
}

.headerInner {
  width: 100%;
  padding: 20px;
  position: absolute;
  margin: 0 auto;
  padding: 30px 0 0 0;
  color: #DDD;
  z-index: 2;
}

.headerLogo {
  max-width: 35%;
}
